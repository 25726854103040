import React from 'react';
import {
  Box,
  Button,
  Container,
  Divider,
  Heading,
  Img,
  Link,
  HStack,
  Tag,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link as GatsbyLink, navigate } from 'gatsby';
import moment from 'src/utils/moment';

export interface CardPostsProps extends GatsbyTypes.CategoryBlogPostQuery {
  prev: string | null;
  next: string | null;
  referer: string;
}

export default function CardPosts({
  allBlogPost,
  prev,
  next,
  referer,
}: CardPostsProps) {
  return (
    <Container maxW="container.xl" px={2} py={[4, 16]}>
      {prev && (
        <Box m={4}>
          <Button
            variant="outline"
            onClick={() => {
              navigate(prev);
            }}
          >
            <Text as="span" className="iconfont icon-arrow-left" mr={4} />
            更新的文章
          </Button>
        </Box>
      )}

      {allBlogPost.edges.map((post, i) => (
        <Box maxW="800px" m="auto" key={post.node.id} my={16}>
          {post.node.tags && (
            <HStack h={4}>
              {post.node.tags.map((tag, ii) => (
                <React.Fragment key={tag}>
                  {ii !== 0 && <Divider orientation="vertical" />}
                  <Tag colorScheme={['blue', 'red', 'purple'][i % 3]}>
                    {tag}
                  </Tag>
                </React.Fragment>
              ))}
            </HStack>
          )}

          {post.node.image && (
            <Img
              my={4}
              as={GatsbyImage}
              alt="post img"
              borderRadius="md"
              boxShadow="primary"
              image={post.node.image?.childImageSharp?.gatsbyImageData!}
            />
          )}

          <Heading my={4} size="lg">
            <Link
              as={GatsbyLink}
              to={post.node.slug}
              state={{ referer }}
              variant="now"
              colorScheme="now"
            >
              {post.node.title}
            </Link>
          </Heading>
          <Text className="card-description">{post.node.excerpt}</Text>
          <Text
            color={useColorModeValue('gray.500', 'gray.400')}
            fontSize="sm"
            my={4}
          >
            {moment(post.node.date).fromNow()}
          </Text>
      </Box>
      ))}

      {next && (
        <Box m={4}>
          <Button
            variant="outline"
            onClick={() => {
              navigate(next);
            }}
          >
            <Text as="span" className="iconfont icon-arrow-right" mr={4} />
            更旧的文章
          </Button>
        </Box>
      )}
    </Container>
  );
}
