import React from 'react';
import { useLocation } from '@gatsbyjs/reach-router';
import { graphql } from 'gatsby';
import CardPosts from './CardPosts';
import ImagePosts from './ImagePosts';
import PhotoPosts from './PhotoPosts';

interface Props {
  data: GatsbyTypes.CategoryBlogPostQuery;
  pageContext: {
    category: string;
    prev: string | null;
    next: string | null;
  };
}

export default function Posts({ data, pageContext }: Props) {
  const location = useLocation();
  let posts: React.ReactNode;
  switch (pageContext.category) {
    case 'story':
      posts = (
        <CardPosts
          allBlogPost={data.allBlogPost}
          prev={pageContext.prev}
          next={pageContext.next}
          referer={location.pathname}
        />
      );
      break;
    case 'photography':
      posts = (
        <PhotoPosts
          allBlogPost={data.allBlogPost}
          prev={pageContext.prev}
          next={pageContext.next}
          referer={location?.pathname}
        />
      );
      break;
    default:
      posts = (
        <ImagePosts
          allBlogPost={data.allBlogPost}
          prev={pageContext.prev}
          next={pageContext.next}
          referer={location?.pathname}
        />
      );
  }
  return <React.Fragment>{posts}</React.Fragment>;
}

export const query = graphql`
  query CategoryBlogPost($category: String!, $skip: Int!, $limit: Int!) {
    allBlogPost(
      limit: $limit
      skip: $skip
      filter: { category: { eq: $category } }
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          id
          tags
          title
          date
          excerpt
          body
          slug
          image {
            childImageSharp {
              gatsbyImageData(aspectRatio: 1.333)
            }
          }
        }
      }
    }
  }
`;
