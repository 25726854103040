import { Box, Button, Container, Flex, Link, Text } from '@chakra-ui/react';
import { Link as GatsbyLink, navigate } from 'gatsby';
import React from 'react';
import PhotoMDXRenderer from './PhotoMDXRenderer';


export interface PhotoPostsProps extends GatsbyTypes.CategoryBlogPostQuery {
  prev: string | null;
  next: string | null;
  referer: string;
}

export default function PhotoPosts({
  allBlogPost,
  prev,
  next,
  referer
}: PhotoPostsProps) {
  return (
    <Box px={0} py={[4, 16]}>
      {prev && (
        <Box m={4}>
          <Button
            variant="outline"
            onClick={() => {
              navigate(prev);
            }}
          >
            <Text as="span" className="iconfont icon-arrow-left" mr={4} />
            更新的文章
          </Button>
        </Box>
      )}

      {allBlogPost.edges.map(post => (
        <Box my={16} key={post.node.id}>
          <PhotoMDXRenderer excerpt title={post.node.title}>{post.node.body}</PhotoMDXRenderer>

          <Flex m={4} flexDir="row-reverse">
            <Link
              as={GatsbyLink}
              to={post.node.slug}
              state={{ referer }}
              variant="now"
              colorScheme="now"
            >
              查看更多
            </Link>
          </Flex>
        </Box>

      ))}

      {next && (
        <Box m={4}>
          <Button
            variant="outline"
            onClick={() => {
              navigate(next);
            }}
          >
            <Text as="span" className="iconfont icon-arrow-right" mr={4} />
            更旧的文章
          </Button>
        </Box>
      )}
    </Box>
  );
}
